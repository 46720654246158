import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PositionsComponent} from './positions.component';
import {PositionsDetailsComponent} from './positions-details/positions-details.component';
import {
    PositionsPreviewCardComponent
} from './positions-previews/positions-preview-card/positions-preview-card.component';
import {PositionsChipsComponent} from './positions-chips/positions-chips.component';
import {PositionsSingleChipComponent} from './positions-chips/positions-single-chip/positions-single-chip.component';
import {
    PositionsPreviewsPaginatorComponent
} from './positions-previews/positions-previews-paginator/positions-previews-paginator.component';
import {
    PositionsDetailsBenefitCardComponent
} from './positions-details/positions-details-benefits/positions-details-benefit-card/positions-details-benefit-card.component';
import {
    PositionsDetailsBenefitsComponent
} from './positions-details/positions-details-benefits/positions-details-benefits.component';
import {
    PositionsDetailsInfoChipsComponent
} from './positions-details/positions-details-info-chips/positions-details-info-chips.component';
import {PositionsSearchbarFilter} from './positions-searchbar/positions-searchbar-filter/positions-searchbar-filter';
import {
    FilterLocationFormComponent
} from './positions-searchbar/positions-searchbar-filter/filter-location-form/filter-location-form.component';
import {
    FilterProfessionFormComponent
} from './positions-searchbar/positions-searchbar-filter/filter-profession-form/filter-profession-form.component';
import {
    FilterOtherFormComponent
} from './positions-searchbar/positions-searchbar-filter/filter-other-form/filter-other-form.component';
import {
    PositionsDetailsSkeletonComponent
} from './positions-details/positions-details-skeleton/positions-details-skeleton.component';
import {
    FilterSelectableChipComponent
} from "./positions-searchbar/positions-searchbar-filter/filter-selectable-chip/filter-selectable-chip.component";
import {PositionSearchBarComponent} from "./positions-searchbar/position-search-bar/position-search-bar.component";
import {PositionsSearchOptionsComponent} from "./positions-searchbar/positions-search-options.component";
import {
    FilterSearchInputDropdownComponent
} from './positions-searchbar/positions-searchbar-filter/filter-search-input-dropdown/filter-search-input-dropdown.component';
import {PositionsPreviewsComponent} from "./positions-previews/positions-previews.component";
import {PositionUspChipsComponent} from './positions-details/position-usp-chips/position-usp-chips.component';
import {
    PositionUspChipComponent
} from './positions-details/position-usp-chips/position-usp-chip/position-usp-chip.component';
import {DynamicFontDirective} from "./dynamic-font.directive";
import {
    PositionsWrapperPreviewDetailsComponent
} from "./positions-wrapper-preview-details/positions-wrapper-preview-details.component";
import {
    PositionsChipsSkeletonComponent
} from "./positions-chips/positions-chips-skeleton/positions-chips-skeleton.component";
import {ImageGalleryComponent} from "./image-gallery/image-gallery.component";
import {SearchService} from "./search.service";
import {
    LocationDropdownContentComponent
} from "./positions-searchbar/positions-searchbar-filter/filter-location-form/location-dropdown-content/location-dropdown-content.component";
import {ReactiveFormsModule} from "@angular/forms";
import {UtilityModule} from "../utility/utility.module";
import {LocationResource, PublicEmploymentPositionResource} from "../../generated/resources";
import {
    FilterCityPickerComponent
} from './positions-searchbar/positions-searchbar-filter/filter-city-picker/filter-city-picker.component';
import { PositionsRecommendButtonComponent } from './positions-recommend-button/positions-recommend-button.component';
import { RecommendedOnlyFilterComponent } from './positions-searchbar/positions-searchbar-filter/recommended-only-filter/recommended-only-filter.component';

@NgModule({
    declarations: [
        DynamicFontDirective,
        PositionsComponent,
        PositionsDetailsComponent,
        PositionsPreviewCardComponent,
        PositionsSearchOptionsComponent,
        PositionSearchBarComponent,
        PositionsChipsComponent,
        PositionsSingleChipComponent,
        PositionsPreviewsPaginatorComponent,
        PositionsDetailsBenefitCardComponent,
        PositionsDetailsBenefitsComponent,
        PositionsDetailsInfoChipsComponent,
        PositionsSearchbarFilter,
        FilterLocationFormComponent,
        FilterProfessionFormComponent,
        FilterOtherFormComponent,
        PositionsDetailsSkeletonComponent,
        FilterSelectableChipComponent,
        FilterSearchInputDropdownComponent,
        PositionsPreviewsComponent,
        PositionUspChipsComponent,
        PositionUspChipComponent,
        PositionsWrapperPreviewDetailsComponent,
        PositionsChipsSkeletonComponent,
        ImageGalleryComponent,
        LocationDropdownContentComponent,
        FilterCityPickerComponent,
        PositionsRecommendButtonComponent,
        RecommendedOnlyFilterComponent,
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        UtilityModule,
    ],
    providers: [
        SearchService,
        PublicEmploymentPositionResource,
        LocationResource,
    ],
    exports: [
        PositionsComponent,
        PositionsWrapperPreviewDetailsComponent,
    ],
})

export class PositionsModule {
}

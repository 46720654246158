import 'rxjs/add/operator/do';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Observable} from "rxjs/Rx";
import {Injectable} from "@angular/core";
import {AuthService} from "./auth.service";
import {Router} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(
      private authService: AuthService,
      private router: Router,
      private snackBar: MatSnackBar) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request).do((event: HttpEvent<any>) => {
    }, (err: any) => {
      if (err instanceof HttpErrorResponse) {
        if (err.status === 401) {
          this.authService.logout();
          this.router.navigateByUrl("/login")
        }

        if (err.status === 500 && request.url.includes("main/consultant/talents/") && request.url.endsWith("/evaluation")) {
            this.snackBar.open("Beim Erstellen des AECDisc-Codes ist ein Fehler aufgetreten. Bitte kontaktiere den Support, dieser kann dir auch einen Code erstellen.", null, {duration: 10000})
        }
      }
    });
  }
}

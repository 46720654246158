import {Component} from '@angular/core';
import {SideSheetRef} from "../../utility/side-sheet/sideSheetRef";

@Component({
  selector: 'talent-profile-explanation-dialog',
  templateUrl: './talent-profile-explanation-dialog.component.html',
  styleUrls: ['./talent-profile-explanation-dialog.component.scss']
})
export class TalentProfileExplanationDialogComponent {
  constructor(
    public sideSheetRef: SideSheetRef,
  ) {}

  close(dontShowAgain: boolean) {
    this.sideSheetRef.sheetClosed.next(dontShowAgain);
  }
}

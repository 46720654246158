import {BrowserModule} from '@angular/platform-browser';
import {ErrorHandler, NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {MatSidenavModule} from '@angular/material/sidenav';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FlexLayoutModule, FlexModule} from '@angular/flex-layout';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {MatButtonModule} from '@angular/material/button';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatBadgeModule} from '@angular/material/badge';
import {RouterModule} from '@angular/router';
import {AuthGuardService} from './service/auth-guard.service';
import {LoginComponent} from './login/login.component';
import {AuthService} from './service/auth.service';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {NotFoundComponent} from './not-found/not-found.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {JwtModule} from '@auth0/angular-jwt';
import {
    AccountResource as UniSignAccountResource,
    PublicResource as UniSignPublicResource
} from '../generated/unisign/resources';
import {environment} from '../environments/environment';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {
    AccountResource,
    BenefitResource,
    CoachingTypeResource,
    ConsultantAccountResource,
    ConsultantAppointmentResource,
    ConsultantChatResource,
    ConsultantCoachingResource,
    ConsultantControllingResource,
    ConsultantEmploymentPositionResource,
    ConsultantHidEveResource,
    ConsultantNotificationConfigurationResource,
    ConsultantNotificationResource,
    ConsultantPositionSearchResource,
    ConsultantPrimeRecommendationResource,
    ConsultantProfessionFieldsResource,
    ConsultantProfileResource,
    ConsultantTalentProfilePictureResource,
    ConsultantTalentResource,
    LocationResource,
    LoginLoggingResource,
} from '../generated/resources';
import {ResourceModule} from '@ngx-resource/handler-ngx-http';
import {DialogSheetsModule} from './dialog-sheets/dialog-sheets.module';
import {ConsultantAuthService} from './service/consultant.auth.service';
import {ReactiveFormsModule} from '@angular/forms';
import {TalentsComponent} from './talents/talents.component';
import {MatTableModule} from '@angular/material/table';
import {MatSortModule} from '@angular/material/sort';
import {JwtInterceptor} from './service/jwt.interceptor';
import {MatPaginatorModule} from '@angular/material/paginator';
import {ConsultantProfileComponent} from './consultant-profile/consultant-profile.component';
import {FileService} from './service/file.service';
import {ProfilePreviewComponent} from './consultant-profile/profile-preview/profile-preview.component';
import {MatMenuModule} from '@angular/material/menu';
import {MatSelectModule} from '@angular/material/select';
import {UtilityService} from './service/utility-service';
import {NotificationsComponent} from './notifications/notifications.component';
import {SettingsComponent} from './settings/settings.component';
import {EmailNotificationsComponent} from './settings/email-notifications/email-notifications.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {PrivacyAgreementComponent} from './privacy-agreement/privacy-agreement.component';
import {BugsnagErrorHandler} from '@bugsnag/plugin-angular';
import bugsnag from '@bugsnag/js';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {UtilityModule} from './utility/utility.module';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {
    MAT_MOMENT_DATE_ADAPTER_OPTIONS,
    MatMomentDateModule,
    MomentDateAdapter
} from '@angular/material-moment-adapter';
import {ChangePasswordComponent} from './settings/change-password/change-password.component';
import {OnboardingComponent} from './onboarding/onboarding.component';
import {MatStepperModule} from "@angular/material/stepper";
import {
    PrivacyAgreementContentComponent
} from './privacy-agreement/privacy-agreement-content/privacy-agreement-content.component';
import {
    AppointmentRequestsComponent
} from './consultant-appointments/appointment-requests/appointment-requests.component';
import {
    ConsultantAvailabilityComponent
} from './consultant-appointments/consultant-availability/consultant-availability.component';
import {ConsultantAppointmentsComponent} from './consultant-appointments/consultant-appointments.component';
import {MatTabsModule} from "@angular/material/tabs";
import {
    CreateConsultantAvailabilityDialogComponent
} from './consultant-appointments/consultant-availability/create-consultant-availability-dialog/create-consultant-availability-dialog.component';
import {
    NgxMatDatetimePickerModule,
    NgxMatNativeDateModule,
    NgxMatTimepickerModule
} from "@angular-material-components/datetime-picker";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {NotRatedTalentsComponent} from './dashboard/not-rated-talents/not-rated-talents.component';
import {NotRatedTalentCardComponent} from './dashboard/not-rated-talent-card/not-rated-talent-card.component';
import {OneTimeTokenLoginComponent} from "./one-time-token-login/one-time-token-login.component";
import {ChatOverviewComponent} from "./chat-overview/chat-overview.component";
import {PositionsModule} from "./positions-search/positions.module";

export function tokenGetter() {
    return localStorage.getItem('access_token');
}

export function errorHandlerFactory() {
    let releaseStage = 'production';
    if (!environment.production) {
        return new ErrorHandler()
    }
    return new BugsnagErrorHandler(bugsnag.start({apiKey: '897c7917b2361951a6fa6cd6eeb40c40', releaseStage: releaseStage}));
}

export const MY_FORMATS = {
    parse: {
        dateInput: 'DD-MM-YYYY',
    },
    display: {
        dateInput: 'DD.MM.YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'DD.MM.YYYY',
        monthYearA11yLabel: 'DD.MM.YYYY',
    },
};

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        NotFoundComponent,
        DashboardComponent,
        TalentsComponent,
        ConsultantProfileComponent,
        ProfilePreviewComponent,
        NotificationsComponent,
        SettingsComponent,
        EmailNotificationsComponent,
        PrivacyAgreementComponent,
        ChangePasswordComponent,
        OnboardingComponent,
        PrivacyAgreementContentComponent,
        AppointmentRequestsComponent,
        ConsultantAvailabilityComponent,
        ConsultantAppointmentsComponent,
        CreateConsultantAvailabilityDialogComponent,
        NotRatedTalentsComponent,
        NotRatedTalentCardComponent,
        OneTimeTokenLoginComponent,
        ChatOverviewComponent
    ],
    imports: [
        MatMomentDateModule,
        ResourceModule.forRoot(),
        BrowserModule,
        BrowserAnimationsModule,
        FlexLayoutModule,
        MatSidenavModule,
        FlexModule,
        MatIconModule,
        MatListModule,
        MatButtonModule,
        MatTooltipModule,
        MatBadgeModule,
        MatFormFieldModule,
        MatInputModule,
        HttpClientModule,
        UtilityModule,
        DialogSheetsModule,
        NgxMatTimepickerModule,
        NgxMatDatetimePickerModule,
        MatDatepickerModule,
        NgxMatNativeDateModule,
        MatInputModule,
        JwtModule.forRoot({ // https://github.com/auth0/angular2-jwt
                config: {
                    tokenGetter: tokenGetter,
                    whitelistedDomains: [environment.apiHost, environment.uniSignHost],
                    blacklistedRoutes: [environment.apiUrl + '/oauth/token']
                }
            }
        ),
        ReactiveFormsModule,
        MatTableModule,
        MatSortModule,
        MatPaginatorModule,
        MatMenuModule,
        MatSelectModule,
        MatCheckboxModule,
        MatSlideToggleModule,
        PositionsModule,
        MatStepperModule,
        MatTabsModule,
        RouterModule,
        AppRoutingModule,
    ],
    exports: [
        MatTableModule,
        MatSortModule,
        MatIconModule,
        MatListModule,
        MatButtonModule,
        MatTooltipModule,
        MatBadgeModule,
        MatFormFieldModule,
        MatInputModule,
    ],
    providers: [

        //Services
        AuthGuardService,
        AuthService,
        FileService,
        ConsultantAuthService,
        UtilityService,
        //Resources
        AccountResource,
        ConsultantTalentResource,
        ConsultantProfileResource,
        ConsultantHidEveResource,
        ConsultantAccountResource,
        CoachingTypeResource,
        ConsultantTalentProfilePictureResource,
        ConsultantNotificationResource,
        ConsultantNotificationConfigurationResource,
        ConsultantCoachingResource,
        ConsultantControllingResource,
        ConsultantEmploymentPositionResource,
        ConsultantPrimeRecommendationResource,
        ConsultantAppointmentResource,
        ConsultantChatResource,
        UniSignAccountResource,
        UniSignPublicResource,
        LoginLoggingResource,
        ConsultantPositionSearchResource,
        ConsultantProfessionFieldsResource,
        BenefitResource,
        LocationResource,
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
        },
        {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
        {provide: 'env', useValue: environment},
        {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
        {provide: ErrorHandler, useFactory: errorHandlerFactory},
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}

<div class="chip"
     [ngClass]="{
     'light-teal-chip': chip.type == 'Usp' && !mobileDetailUsps,
     'mobile-detail-usp-chip': chip.type == 'Usp' && mobileDetailUsps,
     'grey-chip': chip.type == 'Origin',
     'primary-usp-chip': chip.type == 'Origin' && mobileDetailUsps
     }"
     [title]="chip.type == 'Origin' ? 'Talentagent importiert auch Stellen von anderen Plattformen wie hier ' + (chip.title == 'Schnellbewerbung' ? 'Gefunden über Talentagent' : chip.title) + ', um das Angebot für die Talente zu erhöhen. Die Unternehmen, die diese Stellen ausschreiben, sind idR keine Uniwunder-Kunden' : ''">
    {{ chip.title == "Schnellbewerbung" ? "Gefunden über Talentagent" : chip.title }}
</div>

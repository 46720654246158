import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FlexModule} from '@angular/flex-layout';
import {SideSheetModule} from '../utility/side-sheet/side-sheet.module';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {UtilityModule} from '../utility/utility.module';
import {MatInputModule} from '@angular/material/input';
import {ReactiveFormsModule} from '@angular/forms';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {TalentProfileDialogComponent} from './talent-profile-dialog/talent-profile-dialog.component';
import {MatSortModule} from '@angular/material/sort';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs';
import {InviteTalentDialogComponent} from './invite-talent-dialog/invite-talent-dialog.component';
import {
    RateTalentContactedDialogComponent
} from './rate-talent-contacted-dialog/rate-talent-contacted-dialog.component';
import {SupportDialogComponent} from './support-dialog/support-dialog.component';
import {RecommendPositionDialogComponent} from './recommend-position-dialog/recommend-position-dialog.component';
import {
    MarkPrimePositionAsUnsuitableDialogComponent
} from './mark-prime-position-as-unsuitable-dialog/mark-prime-position-as-unsuitable-dialog.component';
import {AecdiscInformationDialogComponent} from './aecdisc-information-dialog/aecdisc-information-dialog.component';
import {RouterModule} from "@angular/router";
import {MarkTalentAsHiredDialogComponent} from './mark-talent-as-hired-dialog/mark-talent-as-hired-dialog.component';
import {MatOptionModule} from "@angular/material/core";
import {MatSelectModule} from "@angular/material/select";
import {TalentApplicationsComponent} from './talent-profile-dialog/talent-applications/talent-applications.component';
import {RecommendationInfoDialogComponent} from './recommendation-info-dialog/recommendation-info-dialog.component';
import {MatMenuModule} from "@angular/material/menu";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {MatRadioModule} from "@angular/material/radio";
import {
    ConsultantAppointmentInfoDialogComponent
} from './consultant-appointment-info-dialog/consultant-appointment-info-dialog.component';
import {PositionsModule} from "../positions-search/positions.module";
import { PositionSearchExplanationDialogComponent } from './position-search-explanation-dialog/position-search-explanation-dialog.component';
import { CoachingFileCardComponent } from './talent-profile-dialog/coaching-file-card/coaching-file-card.component';
import {
    TalentProfileExplanationDialogComponent
} from "./talent-profile-explanation-dialog/talent-profile-explanation-dialog.component";


@NgModule({
    declarations: [
        TalentProfileDialogComponent,
        InviteTalentDialogComponent,
        RateTalentContactedDialogComponent,
        SupportDialogComponent,
        RecommendPositionDialogComponent,
        MarkPrimePositionAsUnsuitableDialogComponent,
        AecdiscInformationDialogComponent,
        MarkTalentAsHiredDialogComponent,
        TalentApplicationsComponent,
        RecommendationInfoDialogComponent,
        ConsultantAppointmentInfoDialogComponent,
        PositionSearchExplanationDialogComponent,
        TalentProfileExplanationDialogComponent,
        CoachingFileCardComponent
    ],
    imports: [
        CommonModule,
        FlexModule,
        SideSheetModule,
        MatButtonModule,
        MatIconModule,
        UtilityModule,
        ReactiveFormsModule,
        MatInputModule,
        MatSnackBarModule,
        MatSortModule,
        MatPaginatorModule,
        MatTabsModule,
        MatCheckboxModule,
        MatTooltipModule,
        MatTableModule,
        RouterModule,
        MatOptionModule,
        MatSelectModule,
        MatMenuModule,
        MatSlideToggleModule,
        MatRadioModule,
        PositionsModule,
    ],
    exports: [],

    entryComponents: [
        TalentProfileDialogComponent,
        InviteTalentDialogComponent
    ]
})
export class DialogSheetsModule {
}

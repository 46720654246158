import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UploadedFileData} from "../../../../generated/data";
import {ConsultantCoachingResource} from "../../../../generated/resources";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {UtilityService} from "../../../service/utility-service";

@Component({
  selector: 'app-coaching-file-card',
  templateUrl: './coaching-file-card.component.html',
  styleUrls: ['./coaching-file-card.component.scss']
})
export class CoachingFileCardComponent implements OnInit {


  @Input() previewImage: string = 'Preview Image'
  @Input() file!: UploadedFileData;
  @Input() isDownloadFile: boolean = true;
  @Input() disableFileForDownload: boolean = true;
  @Input() coachingId: number = 0
  fileType: string = ''
  @Output() deleteFileEmitter: EventEmitter<boolean> = new EventEmitter<boolean>()

  hover : boolean = false

  constructor(
      private consultantCoachingResource: ConsultantCoachingResource,
      private http: HttpClient,
      private utilService: UtilityService
  ) { }

  ngOnInit(): void {
    this.setFileType();
  }

  private setFileType(): void {
    if (!this.file?.fileName) return;

    const extension = this.file.fileName.split('.').pop()?.toLowerCase();

    if (!extension) {
      this.fileType = 'unknown';
      return;
    }

    const fileTypeMap: { [key: string]: string } = {
      'pdf': 'pdf',
      'doc': 'doc',
      'docx': 'doc',
      'xls': 'xls',
      'xlsx': 'xls',
      'ppt': 'ppt',
      'pptx': 'ppt',
      'png': 'img',
      'jpg': 'img',
      'jpeg': 'img',
      'gif': 'img',
      'svg': 'img',
      'bmp': 'img',
      'txt': 'txt',
      'zip': 'zip',
      'rar': 'zip',
      '7z': 'zip',
      'mp3': 'audio',
      'wav': 'audio',
      'mp4': 'video',
      'avi': 'video',
      'mov': 'video',
      'mkv': 'video'
    };

    this.fileType = fileTypeMap[extension] || 'unknown';
  }

  downloadFile(): void {
    this.http
        .get(environment.apiUrl + `/consultant/coaching/${this.coachingId}/files/${this.file.id}` , {
          responseType: 'blob', // Expect the response as a Blob
        })
        .subscribe({
          next: (blob) => {
            // Create a link element and trigger download
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            anchor.href = url;
            anchor.download = this.file.fileName; // Use the provided fileName
            document.body.appendChild(anchor);
            anchor.click();
            document.body.removeChild(anchor);
            window.URL.revokeObjectURL(url); // Clean up the URL object
            this.utilService.showSnackBar('Download gestartet für: ' + this.file.fileName)
          },
          error: (error) => {
            console.error('Error downloading file:', error);
          },
        });
  }

  deleteFile(): void {
    this.consultantCoachingResource.deleteCoachingFile({fileId: this.file.id, coachingId: this.coachingId}).then(res => {
      this.utilService.showSnackBar(this.file.fileName + ' erfolgreich gelöscht')
      this.deleteFileEmitter.emit(true)
    })
  }
}

import {Component, ComponentRef, ElementRef, OnInit, ViewChild} from '@angular/core';
import {
  ChatData,
  CoachingOfferData,
  ConsultantTalentEventHistoryData,
  ConsultantTalentProfileData,
  TalentEvaluationData,
  UploadedFileData
} from '../../../generated/data';
import {
  CoachingTypeResource,
  ConsultantChatResource,
  ConsultantCoachingResource,
  ConsultantTalentProfilePictureResource,
  ConsultantTalentResource
} from '../../../generated/resources';
import {FileService} from '../../service/file.service';
import {environment} from '../../../environments/environment';
import {FormControl} from '@angular/forms';
import {UtilityService} from '../../service/utility-service';
import {DialogService} from '../../utility/side-sheet/dialog.service';
import {
  RateTalentContactedDialogComponent
} from "../rate-talent-contacted-dialog/rate-talent-contacted-dialog.component";
import {ConsultantAuthService} from "../../service/consultant.auth.service";
import {AecdiscInformationDialogComponent} from "../aecdisc-information-dialog/aecdisc-information-dialog.component";
import {MarkTalentAsHiredDialogComponent} from "../mark-talent-as-hired-dialog/mark-talent-as-hired-dialog.component";
import {DatePipe} from "@angular/common";
import {ChatService} from "../../service/chat.service";
import {SideSheetRef} from 'src/app/utility/side-sheet/sideSheetRef';
import {HttpClient} from "@angular/common/http";
import {
  PositionSearchExplanationDialogComponent
} from "../position-search-explanation-dialog/position-search-explanation-dialog.component";
import {
  TalentProfileExplanationDialogComponent
} from "../talent-profile-explanation-dialog/talent-profile-explanation-dialog.component";

@Component({
  selector: 'app-talent-profile',
  templateUrl: './talent-profile-dialog.component.html',
  styleUrls: ['./talent-profile-dialog.component.scss']
})
export class TalentProfileDialogComponent implements OnInit {

  public talentProfileData: ConsultantTalentProfileData;
  public talentEventHistoryData: ConsultantTalentEventHistoryData[];
  public talentApplicationCount: number;
  public talentPositionProposalCount: number;
  public displayedColumnsEventHistory: string[] = ['date', 'event'];
  public profilePicture = '../assets/images/blurred_user.png';
  public chatData: ChatData

  comment: FormControl = new FormControl('');

  talentEvaluationData: TalentEvaluationData

  loadingTalentEvaluation: boolean = false
  refreshingConsultantEvaluationGroup: boolean = false
  downloadingAecdiscResult: boolean = false
  loadingReleaseAecdiscResult: boolean = false
  loadingProfile: boolean = true


  uploadedFiles: UploadedFileData[] = [];
  downloadFiles: UploadedFileData[] = [];
  isDragOver: boolean = false;
  coachingOffers: CoachingOfferData[] = [];

  //input
  talentId: number;
  tabIndex: number = 0;

  sevenDaysAgo: Date = new Date()

  constructor(
      public authService: ConsultantAuthService,
      public utilityService: UtilityService,
      private sideSheetRef: SideSheetRef,
      private talentResource: ConsultantTalentResource,
      private consultantTalentProfilePictureResource: ConsultantTalentProfilePictureResource,
      private coachingTypeResource: CoachingTypeResource,
      private fileService: FileService,
      private consultantCoachingResource: ConsultantCoachingResource,
      private dialogService: DialogService,
      private datePipe: DatePipe,
      private chatResource: ConsultantChatResource,
      public chatService: ChatService,
      private http: HttpClient
  ) {
      this.sevenDaysAgo.setDate( new Date().getDate() - 7)
  }

  ngOnInit(): void {
    this.loadAllData();
    this.loadCoachings();
  }


  get shouldShowContactInformationExplanation() {
    return !this.hasDismissedContactInformationExplanation;
  }
  private hasDismissedContactInformationExplanation = localStorage.getItem('talent-profile.video-explanation-dismissed') === 'true';

  showFullExplanation() {
    const dialog: ComponentRef<PositionSearchExplanationDialogComponent> = this.dialogService.openOverlay(TalentProfileExplanationDialogComponent, '1000px');

    dialog.instance.sideSheetRef.sheetClosed.subscribe(dontShowAgain => {
      if (dontShowAgain) {
        this.hideExplanation();
      }
    });
  }

  hideExplanation() {
    this.hasDismissedContactInformationExplanation = true;
    localStorage.setItem('talent-profile.video-explanation-dismissed', 'true');
  }

  getTalentFinishStudyString(): string {
    if (!this.talentProfileData.endDate) return "n.a."
    let dateDiff = (new Date(this.talentProfileData.endDate).getTime() - new Date(this.talentProfileData.createdAt).getTime())/ 1000 / 60 / 60 / 24
    if (dateDiff <= 0) return 'schon fertig '
    if (dateDiff <= 90) return 'innerhalb von 3 Monaten '
    if (dateDiff <= 180) return 'innerhalb von 6 Monaten '
   return 'mehr als 6 Monate '
  }

  getMlpAplicationsTooltip(): string {
    let tooltipPositions: string = "Talent hat sich auf folgende MLP-Stellen Beworben: "
    this.talentProfileData.mlpApplicationsData.forEach((item, index) => {
      tooltipPositions = tooltipPositions + (item.employmentPosition.name + " (" + this.datePipe.transform(item.applicationDate, "dd.MM.yy") + ")" + (index+1 == this.talentProfileData.mlpApplicationsData.length? "" : ", "))
    })
    return tooltipPositions
  }

  loadCoachings() {
    if(!this.talentId) return
    this.consultantCoachingResource.getCoachingOffersFromTalent(this.talentId).then(coachings => {
      this.coachingOffers = coachings
    })
  }

  loadAllData(): void {
    this.loadProfileData();
    this.getTalentEvaluation();
    this.getTalentApplicationCount();
    this.getTalentPositionProposalCount();
    this.loadTalentEventHistory();
    this.loadChat();
  }

  openAecdiscInformationDialog() {
   this.dialogService.openOverlay(AecdiscInformationDialogComponent, '600px');

  }

  openRateTalentContactedDialog(talentId: number) {
    const comp = this.dialogService.openOverlay(RateTalentContactedDialogComponent, '600px');
    comp.instance.talentId = talentId
    let subscription = comp.instance.sideSheetRef.sheetClosed.subscribe(result => {
      if (result) this.loadProfileData()
      subscription.unsubscribe();
    });
  }

  loadTalentEventHistory(): void {
    this.talentResource.getEventHistoryOfTalent(this.talentId).then(events => {
      this.talentEventHistoryData = events
    })
  }

  loadProfileData(): void {
    this.loadingProfile = true
    this.talentResource.getTalent(this.talentId).then(profileData => {
      this.talentProfileData = profileData;
      this.comment.setValue(this.talentProfileData.coaching.comment);
      this.loadProfilePic();
      this.refreshTalentCoachingFiles()
      this.refreshUploadedFiles()
      this.loadingProfile = false
    });
  }

  loadChat(): void {
    this.chatResource.getChatForTalent(this.talentId).then(result => {
      if (result.id) this.chatData = result
      else {
        this.chatData = this.chatService.createDummyChat()
      }
    })
  }

  updateContactComment() {
    this.consultantCoachingResource.updateCoachingComment(this.talentProfileData.coaching.id, {comment: this.comment.value}).then(result => {
      this.utilityService.showSnackBar('Notiz bearbeitet');
    });
  }

  loadProfilePic() {
    if (this.talentProfileData.profilePictureId != null) {
      this.consultantTalentProfilePictureResource.getProfileImageHash(this.talentId).then(imageHash => {
        this.profilePicture = environment.apiUrl + `/consultant/talents/${this.talentId}/profilePicture/${imageHash}`
      })
    }
  }

  getTalentApplicationCount() {
    this.talentResource.getTalentApplicationsCount(this.talentId).then(count => {
      this.talentApplicationCount = count.value
    })
  }

  getTalentPositionProposalCount() {
    this.talentResource.getTalentPositionProposalCount(this.talentId).then(count => {
      this.talentPositionProposalCount = count.value;
    });
  }

  routeToAdapt() {
    window.open(environment.adaptFrontendUrl + '/contacts?talentId=' + this.talentProfileData.id, '_blank')
  }

  downloadCv(): void {
    this.fileService.downloadCv(this.talentId);
  }

  createTalentEvaluation() {
    this.loadingTalentEvaluation = true
    this.consultantCoachingResource.createTalentEvaluation(this.talentId).then(result => {
      this.talentEvaluationData = result
      this.loadingTalentEvaluation = false
    })
  }

  getTalentEvaluation() {
    this.loadingTalentEvaluation = true
    this.consultantCoachingResource.getTalentEvaluation(this.talentId).then(result => {
      if (result?.evaluationId) this.talentEvaluationData = result
      this.loadingTalentEvaluation = false
    })
  }

  openTalentEvaluationResult() {
    this.fileService.getEvaluationResult(this.talentId).subscribe(blob => {
      window.open(URL.createObjectURL(blob), '_blank')
    })
  }

  openMarkTalentAsHiredDialog() {
    let comp = this.dialogService.openOverlay(MarkTalentAsHiredDialogComponent, '600px')
    comp.instance.talentId = this.talentProfileData.id
    let subscription = comp.instance.sideSheetRef.sheetClosed.subscribe(result => {
      if (result) this.talentProfileData.talentMarkedAsHired = true
      subscription.unsubscribe();
    });
  }


  triggerRefreshTalentAecdisc() {
    this.refreshingConsultantEvaluationGroup = true
    this.consultantCoachingResource.updateConsultantEvaluationGroup().then(result => {
      this.getTalentEvaluation();
      this.refreshingConsultantEvaluationGroup = false
    })
  }

  downloadAecdiscResult() {
    this.downloadingAecdiscResult = true
    this.fileService.downloadEvaluationResult(this.talentId);
    this.downloadingAecdiscResult = false
  }

  releaseAecdiscResult() {
    this.loadingReleaseAecdiscResult = true
    this.consultantCoachingResource.approveTalentEvaluation(this.talentId).then((result) => {
      this.getTalentEvaluation();
      this.loadingReleaseAecdiscResult = false
    })
  }

    isActive(lastLogin?: Date): Boolean {
        if (!lastLogin) return false
        return new Date(lastLogin) >= this.sevenDaysAgo
    }



  onDragOver(event: DragEvent): void {
    event.preventDefault();
    this.isDragOver = true;
  }

  onDragLeave(event: DragEvent): void {
    event.preventDefault();
    this.isDragOver = false;
  }

  // Handle file drop
  onDrop(event: DragEvent): void {
    event.preventDefault();
    this.isDragOver = false;

    if (event.dataTransfer && event.dataTransfer.files) {
      Array.from(event.dataTransfer.files).forEach(file => this.uploadFile(file));
    }
  }

  onFileInputChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files) {
      Array.from(input.files).forEach(file => this.uploadFile(file));
    }
  }

  private uploadFile(file: File): void {
    if (!this.talentProfileData?.coaching?.id) {
      console.error('Coaching ID is missing');
      return;
    }

    const formData = new FormData();
    formData.append('file', file); // Add the file to FormData

    const coachingId = this.talentProfileData.coaching.id; // Extract the coaching ID

    // Allowed file types
    const allowedTypes = [
      'application/pdf',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
      'image/png', 'image/jpeg', 'image/gif', 'image/svg+xml', 'image/bmp',
      'text/plain'
    ];

    const maxSize = 5 * 1024 * 1024; // 5MB limit

    // Validate file type
    if (!allowedTypes.includes(file.type)) {
      this.utilityService.showSnackBar('Ungültiger Dateityp. Bitte laden Sie eine PDF, Word-Datei, Excel-Datei oder ein Bild hoch.');
      return;
    }

    // Validate file size
    if (file.size > maxSize) {
      this.utilityService.showSnackBar('Die Datei ist zu groß. Maximal erlaubte Größe: 5MB.');
      return;
    }

    // Use Angular's HttpClient to send the request
    this.http
        .post(environment.apiUrl +
            `/consultant/coaching/${coachingId}/files/uploadFile`, // Backend endpoint
            formData,
            {
              params: { fileName: file.name },  responseType: 'text' as 'json'
            }
        )
        .subscribe({
          next: () => {
            this.refreshUploadedFiles(); // Refresh the file list on success
          },
          error: (error) => {
            console.error('Error uploading file:', error);
            setTimeout(() => {
              this.refreshUploadedFiles(); // Ensure refresh is triggered
            }, 500);
          },
        });
  }

  refreshUploadedFiles(): void {

    const coachingId = this.talentProfileData.coaching.id

    this.consultantCoachingResource.getOwnFilesForCoaching(this.talentProfileData.coaching.id).then((files: []) => {
      this.uploadedFiles = files
    });
  }

  private refreshTalentCoachingFiles(): void {
    const coachingId = this.talentProfileData.coaching.id
    this.consultantCoachingResource.getCoachingFilesFromTalent(coachingId).then((files: any[]) => {
      this.downloadFiles = files
    });
  }

  updateCoaching: boolean = false

  completeCoaching(coaching: CoachingOfferData, completed: boolean){
      this.updateCoaching = true
      coaching.completed = completed
    this.consultantCoachingResource.updateCoachingOfferCompletedState(coaching.id, {completed: completed}).then(() => {
      this.loadCoachings()
        this.updateCoaching = false
    })
  }

}

<div fxLayout="column" fxLayoutAlign="start start" *ngIf="!loadingConfig">
    <div fxLayout="row" fxLayoutAlign="space-between start" class="config-setting width-100">
        <div fxLayout="column" fxLayoutAlign="start start">
            <div class="config-headline">Talent wurde von einem Unternehmen aussortiert</div>
            <div class="config-description">Du erhältst diese E-Mail, wenn die Bewerbung eines Talents von dem
                Unternehmen abgelehnt wurde
            </div>
        </div>
        <mat-checkbox color="primary" [(ngModel)]="config.emailsForTalentRejectedByCompany"></mat-checkbox>
    </div>

    <div fxLayout="row" fxLayoutAlign="space-between start" class="config-setting width-100">
        <div fxLayout="column" fxLayoutAlign="start start">
            <div class="config-headline">Talent wurde von einem Unternehmen eingestellt</div>
            <div class="config-description">Diese E-Mail erhältst du, wenn ein Talent von einem Unternehmen eingestellt
                wurde
            </div>
        </div>
        <mat-checkbox color="primary" [(ngModel)]="config.emailsForTalentHiredByCompany"></mat-checkbox>
    </div>

    <div fxLayout="row" fxLayoutAlign="space-between start" class="config-setting width-100">
        <div fxLayout="column" fxLayoutAlign="start start">
            <div class="config-headline">Dir wurde ein neues Talent zugeordnet</div>
            <div class="config-description">Du erhältst eine E-Mail wenn du ein neues Talent erhalten hast</div>
        </div>
        <mat-checkbox color="primary" [(ngModel)]="config.emailsForNewTalentAssigned"></mat-checkbox>
    </div>

    <div fxLayout="row" fxLayoutAlign="space-between start" class="config-setting width-100">
        <div fxLayout="column" fxLayoutAlign="start start">
            <div class="config-headline">Talent hat sich auf eine Stelle beworben</div>
            <div class="config-description">Du bekommst eine E-Mail, wenn sich ein Talent auf eine Stelle beworben hat
            </div>
        </div>
        <mat-checkbox color="primary" [(ngModel)]="config.emailsForTalentTalentAppliedForPosition"></mat-checkbox>
    </div>

    <div fxLayout="row" fxLayoutAlign="space-between start" class="config-setting width-100">
        <div fxLayout="column" fxLayoutAlign="start start">
            <div class="config-headline">Talent hat seinen ersten Lebenslauf hochgeladen</div>
            <div class="config-description">Du bekommst eine E-Mail, wenn ein Talent seinen ersten Lebenslauf
                hochgeladen hat
            </div>
        </div>
        <mat-checkbox color="primary" [(ngModel)]="config.emailsForTalentUploadedFirstCv"></mat-checkbox>
    </div>

    <div fxLayout="row" fxLayoutAlign="space-between start" class="config-setting width-100">
        <div fxLayout="column" fxLayoutAlign="start start">
            <div class="config-headline">Talent wurde nicht matchable markiert</div>
            <div class="config-description">Du bekommst eine E-Mail, wenn ein Talent keine Stellenvorschläge mehr
                erhalten will.
                Beispielsweise wenn es einen Job gefunden hat oder angegeben hat, das es keine Stellenvorschläge mehr
                erhalten möchte
            </div>
        </div>
        <mat-checkbox color="primary" [(ngModel)]="config.emailsForTalentRemovedFromMatching"></mat-checkbox>
    </div>

    <div fxLayout="row" fxLayoutAlign="space-between start" class="config-setting width-100">
        <div fxLayout="column" fxLayoutAlign="start start">
            <div class="config-headline">Talent hat sich abgemeldet</div>
            <div class="config-description">Du bekommst eine E-Mail, wenn eines deiner Talente sich von Talentagent abgemeldet hat.
            </div>
        </div>
        <mat-checkbox color="primary" [(ngModel)]="config.emailsForTalentWithdrawnMlpGdprConsent"></mat-checkbox>
    </div>

    <div fxLayout="row" fxLayoutAlign="space-between start" class="config-setting width-100">
        <div fxLayout="column" fxLayoutAlign="start start">
            <div class="config-headline">Talent hat eine Chat-Nachricht verschickt</div>
            <div class="config-description">Du bekommst eine E-Mail, wenn eines deiner Talente eine neue Chat-Nachricht verschickt.
            </div>
        </div>
        <mat-checkbox color="primary" [(ngModel)]="config.emailsForNewChatMessages"></mat-checkbox>
    </div>

    <div fxLayout="row" fxLayoutAlign="space-between start" class="config-setting width-100">
        <div fxLayout="column" fxLayoutAlign="start start">
            <div class="config-headline">Das Talent hat ein Coaching-Angebot als interessant markiert</div>
            <div class="config-description">Du bekommst eine E-Mail Zusammenfassung, wenn deine Talente ein Coaching-Angebote als interessant markiert haben.
            </div>
        </div>
        <mat-checkbox color="primary" [(ngModel)]="config.emailsForTalentCoachingOfferInteresting"></mat-checkbox>
    </div>

    <div class="width-100 m-top15" fxLayout="row" fxLayoutAlign="end center">
        <loading-button [loadingBool]="savingConfig" color="primary" (clickEvent)="updateConfig()">Speichern
        </loading-button>
    </div>
</div>

<div class="file-card-wrapper">

    <div (mouseenter)="hover = true" (mouseleave)="hover = false" class="file-preview">
        <button (click)="deleteFile()" *ngIf="!isDownloadFile && !hover" class="delete-button">
            <i class="mdi mdi-delete"></i>
        </button>
        <button  (click)="downloadFile()" *ngIf="isDownloadFile && !hover" class="download-button">
            <i class="mdi mdi-download"></i>
        </button>
        <div *ngIf="isDownloadFile && hover" (click)="downloadFile()" class="download-overlay scale-overlay">
            <i class="mdi mdi-download"></i>
        </div>
        <div *ngIf="!isDownloadFile && hover" (click)="deleteFile()" class="delete-overlay scale-overlay">
            <i class="mdi mdi-delete"></i>
        </div>
        <i *ngIf="fileType == 'pdf'" class="mdi mdi-file-pdf-box"></i>
        <i *ngIf="fileType == 'doc'" class="mdi mdi-microsoft-word"></i>
        <i *ngIf="fileType == 'img'" class="mdi mdi-image"></i>
    </div>

    <div class="flex column center-start width-100">
        <p class="center-text bold-text cg-paragraph width-100">{{file.fileName}}</p>
        <p class="center-text cg-paragraph-small width-100">{{file.uploadDate | date: 'dd.MM.yy'}}</p>
    </div>

</div>
<dialog-header [dialogHeadline]="talentProfileData?.firstName + ' ' + talentProfileData?.lastName">
        <active-marker *ngIf="isActive(talentProfileData?.lastLogin)" [lastLogin]="talentProfileData.lastLogin"></active-marker>
</dialog-header>

    <div class="profile-header-content">
        <loading-spinner [loadingBool]="loadingProfile">

        <div class="profile-header width-100" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="8px">
            <div class="profile-header-inner width-100" fxLayout="column" fxLayoutAlign="start center">
                <div class="mlp-application" [matTooltip]="getMlpAplicationsTooltip()" *ngIf="talentProfileData?.mlpApplicationsData?.length"
                    >Achtung! Das Talent hat eine MLP-Bewerbung!</div>

                <div class="width-100" fxLayout="row" fxLayoutAlign="space-between center">
                    <div class="contact-information" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="25px">
                        <div id="profile-picture"
                             [ngStyle]="{'background-image': 'url(' + profilePicture + ')'}">
                        </div>

                        <div>
                            <div class="data-description">Name</div>
                            <div class="data-value" [matTooltip]="talentProfileData?.firstName + ' ' + talentProfileData?.lastName">{{talentProfileData?.firstName}} {{talentProfileData?.lastName}}</div>
                        </div>

                        <div>
                            <div class="data-description">Telefon</div>
                            <div class="data-value"
                                 *ngIf="talentProfileData?.phoneNumber">{{talentProfileData?.phoneNumber}}</div>
                            <div class="missing" *ngIf="!talentProfileData?.phoneNumber">n.a.</div>
                        </div>

                        <div>
                            <div class="data-description">E-Mail</div>
                            <div class="data-value" [matTooltip]="talentProfileData?.emailAddress"
                                 *ngIf="talentProfileData?.emailAddress">{{talentProfileData?.emailAddress}}</div>
                            <div class="missing" *ngIf="!talentProfileData?.emailAddress">n.a.</div>
                        </div>

                        <div>
                            <div class="data-description">Lebenslauf</div>
                            <div class="data-value cv-download" style="color: green" *ngIf="talentProfileData?.cvId != null"
                                 matTooltip="CV Herunterladen"
                                 (click)="downloadCv()">
                                Vorhanden <i class="mdi mdi-download"></i>
                            </div>
                            <div class="missing" style="color: var(--warn);" *ngIf="talentProfileData?.cvId == null">
                                <i class="mdi mdi-alert"></i> fehlt
                            </div>
                        </div>

                    </div>
                    <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="8px" class="info-icons">
                        <i [matTooltip]="'Bewertung eingetragen'" *ngIf="talentProfileData?.talentRated && !talentProfileData?.talentUnreachable" class="mdi mdi-message"></i>
                        <i [matTooltip]="'Talent nicht erreichbar'" *ngIf="talentProfileData?.talentRated && talentProfileData?.talentUnreachable" class="mdi mdi-phone-off"></i>
                        <i [matTooltip]="getMlpAplicationsTooltip()" *ngIf="talentProfileData?.mlpApplicationsData?.length" class="mdi mdi-text-box" style="color: #2ebb00"></i>
                        <i [matTooltip]="'Dieses Talent wurde bereits von dir oder dem Talent selbst als eingestellt gemeldet'" *ngIf="talentProfileData?.talentMarkedAsHired" class="mdi mdi-star"></i>
                        <i [matTooltip]="'Dieses Talent möchte keine Stellenvorschläge mehr erhalten'" *ngIf="talentProfileData?.matchingState == 'MatchingDenied' && !talentProfileData?.deleted" class="mdi mdi-lightbulb-off"></i>
                        <i [matTooltip]="'Dieses Talent hat sich von Talentagent abgemeldet'" *ngIf="talentProfileData?.deleted" class="mdi mdi-alert"></i>
                        <i [matTooltip]="'Dieses Talent wurde in Adapt als verloren markiert'" *ngIf="talentProfileData?.markedAsLost" style="color: var(--warn)" class="mdi mdi-account-alert"></i>

                        <button style="font-size: 14px" mat-flat-button color="primary" *ngIf="!talentProfileData?.talentRated"
                                (click)="openRateTalentContactedDialog(talentProfileData?.id)">
                            <span>Talent bewerten</span>
                        </button>

                        <button mat-icon-button class="matMenuButton"
                                [matMenuTriggerFor]="talentMenu">
                            <i class="mdi mdi-dots-vertical"></i>
                        </button>
                    </div>
                </div>
            </div>

        </div>

        <mat-menu #talentMenu="matMenu">
            <button matTooltip="Kontaktprofil in Adapt öffnen" (click)="routeToAdapt()" mat-menu-item >
                <span>In ADAPT öffnen</span>
            </button>

            <button mat-menu-item color="primary"
                    (click)="openMarkTalentAsHiredDialog()"
                    [disabled]="talentProfileData?.talentMarkedAsHired">
                Als Eingestellt markieren
            </button>
        </mat-menu>
        </loading-spinner>
    </div>


    <mat-tab-group [selectedIndex]="tabIndex">
        <mat-tab label="Kontaktinformationen">

            <button *ngIf="shouldShowContactInformationExplanation" class="explanation-banner" (click)="showFullExplanation()" >
                <button mat-icon-button><i class="mdi mdi-information-outline"></i></button>
                <span>Dieser Bereich hat sich verändert! Hier klicken, um die Neuerungen kennenzulernen.</span>
                <button mat-icon-button (click)="hideExplanation()"><i class="mdi mdi-close"></i></button>
            </button>
            <button *ngIf="!shouldShowContactInformationExplanation" (click)="showFullExplanation()" mat-icon-button class="explanation-icon-button">
                <i class="mdi mdi-information-outline"></i>
            </button>


            <div class="funnel-bar blue inc-7" *ngIf="talentProfileData">
                <ul>
                    <li [ngClass]="{'current': (this.talentProfileData.funnelState | funnelStateValue) == 1}">Onboarding
                    </li>
                    <li [ngClass]="{'current': (this.talentProfileData.funnelState | funnelStateValue) == 2}">Lebenslauf
                    </li>
                    <li [ngClass]="{'current': (this.talentProfileData.funnelState | funnelStateValue) == 3}">Bewerbung
                    </li>
                    <li [ngClass]="{'current': (this.talentProfileData.funnelState | funnelStateValue) == 4}">Einstellung
                    </li>
                </ul>
            </div>
            <div fxLayout="row" class="width-100" style="margin-bottom: 10px">


                <div  class="width-50 flex" fxLayout="column"
                     fxLayoutAlign="start start">
                    <div style="width: calc(100% - 32px)" id="study-info" class="data-wrapper margin-bottom padding">
                        <div fxLayout="row" fxLayoutAlign="space-between center" class="width-100">
                            <div class="data-headline">Studium</div>
                        </div>

                        <div fxLayout="row" fxLayoutAlign="start start" class="width-100">
                            <div class="width-60 data-container" fxLayout="column" fxLayoutAlign="start start">


                                <div fxLayout="column" fxLayoutAlign="start start">
                                    <div class="data-description">Hochschule</div>
                                    <div class="data-value"
                                         *ngIf="talentProfileData?.university">{{talentProfileData.university}}</div>
                                    <div class="missing" *ngIf="!talentProfileData?.university">n.a.</div>
                                </div>

                                <div fxLayout="column" fxLayoutAlign="start start">
                                    <div class="data-description">Studiengang</div>
                                    <div class="data-value"
                                         *ngIf="talentProfileData?.subject">{{talentProfileData?.subject}}</div>
                                    <div class="missing" *ngIf="!talentProfileData?.subject">n.a.</div>
                                </div>

                            </div>

                            <div class="width-40 data-container" fxLayout="column" fxLayoutAlign="start start">

                                <div fxLayout="column" fxLayoutAlign="start start">
                                    <div class="data-description">Abschluss</div>
                                    <div class="data-value"
                                         *ngIf="talentProfileData?.degreeType">{{talentProfileData?.degreeType}}</div>
                                    <div class="missing" *ngIf="!talentProfileData?.degreeType">n.a.</div>
                                </div>

                                <div fxLayout="column" fxLayoutAlign="start start">
                                    <div class="data-description">Abschlussdatum (Angabe des Talents bei der Anmeldung)</div>
                                    <div class="data-value" style="text-overflow: unset; white-space: unset"
                                         *ngIf="talentProfileData?.endDate">{{getTalentFinishStudyString()}} </div>
                                    <div class="missing" *ngIf="!talentProfileData?.endDate">n.a.</div>
                                </div>
                            </div>
                        </div>
                    </div>




                    <div *ngIf="talentProfileData?.coaching" style="width: calc(100% - 32px)" class="padding data-wrapper">
                        <div class="data-headline">Coaching-Ressourcen</div>

                        <div class="width-100">
                            <h3 class="cg-content-header margin-bottom-sm width-100">{{talentProfileData?.firstName}}'s Ressourcen für dich</h3>

                            <div class="download-area">
                                <div
                                        class="width-100 responsive-grid">
                                    <div *ngFor="let file of downloadFiles"
                                         class="grid-item-4">
                                        <app-coaching-file-card
                                                [file]="file"
                                                [coachingId]="talentProfileData?.coaching?.id"
                                                (deleteFileEmitter)="refreshUploadedFiles()"
                                                [isDownloadFile]="true"></app-coaching-file-card>
                                    </div>
                                </div>
                                <div class="width-100 flex center" *ngIf="downloadFiles.length == 0">
                                    <p class="upload-info">{{talentProfileData?.firstName}} hat noch keine Dateien für dich hochgeladen</p>
                                </div>
                            </div>
                        </div>


                        <div
                                class="width-100 margin-bottom-lg">
                            <h3 class="cg-content-header margin-bottom-sm width-100">Dateien für {{talentProfileData?.firstName}} ({{talentProfileData?.firstName}} kann Dateien, die du hier hochlädst sehen)</h3>

                            <div
                                    [class.drag-over]="isDragOver"
                                    (dragover)="onDragOver($event)"
                                    (dragleave)="onDragLeave($event)"
                                    (drop)="onDrop($event)"
                                    class="upload-area">

                                <div
                                        class="responsive-grid margin-bottom width-100">
                                    <div *ngFor="let file of uploadedFiles"
                                         class="grid-item-4">
                                        <app-coaching-file-card
                                                [file]="file"
                                                [coachingId]="talentProfileData?.coaching?.id"
                                                (deleteFileEmitter)="refreshUploadedFiles()"
                                                [isDownloadFile]="false"></app-coaching-file-card>
                                    </div>
                                    <div class="grid-item-4 flex center">
                                        <input
                                                type="file"
                                                id="fileInput"
                                                multiple
                                                (change)="onFileInputChange($event)"
                                                accept=".pdf, .doc, .docx, .xls, .xlsx, .png, .jpg, .jpeg, .gif, .svg, .bmp, .txt"
                                                hidden>
                                        <label for="fileInput" class="big-upload-button upload-button flex center">
                                            <i class="mdi mdi-plus"></i>
                                        </label>
                                    </div>
                                </div>


                                <div class="width-100 flex center">
                                    <p class="upload-info">Dateien hier reinziehen zum hochladen</p>
                                </div>
                            </div>

                        </div>


                    </div>

                </div>



                <div class="padding data-wrapper width-50">
                    <div fxLayout="row" fxLayoutAlign="space-between center">
                        <div class="data-headline">Coaching-Wünsche</div>
                        <a [href]="'assets/images/preview_coachpage.png'" target="_blank" style="font-size: 14px" mat-stroked-button color="primary" >
                            <span>Coach-Page Vorschau</span>
                        </a>
                    </div>
                    <div style="    color: #777;
    font-style: italic;
    margin-bottom: 10px;
    font-size: 12px;">Diese Leistungen findet Steffen interessant. Die Leistungen wurden über deine Coach-Page gefunden und dort angefragt.
                        Du kannst dir deine Coach-Page aus der Sicht von {{talentProfileData?.firstName}} anschauen.
                        <br>
                        Wenn du das jeweilige Coaching mit {{talentProfileData?.firstName}} abgeschlossen hast, kannst du für {{talentProfileData?.firstName}} das Angebot als “Erledigt” markieren.
                    </div>

                    <div *ngFor="let coaching of coachingOffers"
                         class="coaching-request-box flex column start width-100"
                        [class.interested]="coaching.interesting"
                        [class.completed]="coaching.completed"
                    >
                        <div class="width-100 flex row space-between-center">
                            <span>{{coaching?.coachingType.title}}</span>
                            <div class="flex row end">
                                <div *ngIf="coaching.interesting"
                                     class="flex row center margin-right">
                                    <i class="mdi mdi-bookmark-check"></i>
                                    <span class="span-interested">interessiert</span>
                                </div>

                                <button
                                        *ngIf="!coaching.completed"
                                        [disabled]="!coaching.interesting"
                                        (click)="completeCoaching(coaching, true)"
                                        class="coaching-interest-button">Erledigt markieren</button>
                                <button
                                        *ngIf="coaching.completed"
                                        (click)="completeCoaching(coaching, false)"
                                        class="coaching-interest-button clicked">Erledigt</button>
                            </div>
                        </div>

                        <div *ngIf="coaching?.coachingType.title == 'Hilfe im Bewerbungsprozess'" class="width-100 flex column start">
                            <span class="offer-description" style="font-style: italic">Dieses Coaching-Angebot kann folgende Inhalte umfassen:</span>
                            <span class="offer-description">Deine Ansprüche an einen Job</span>
                            <span class="offer-description">Wo kannst du die passende Stelle finden?</span>
                            <span class="offer-description">Vorstellungsgespräch meistern</span>
                            <span class="offer-description">Richtig umgehen mit Zu- und Absagen</span>
                        </div>
                    </div>

                </div>



            </div>
        </mat-tab>
        <mat-tab label="Stellen empfehlen">
            <ng-template matTabContent>
                <app-positions [talentId]="talentId"></app-positions>
            </ng-template>
        </mat-tab>
        <mat-tab label="Bewerbungen">
            <ng-template matTabContent>
                <talent-applications [talentId]="talentId"></talent-applications>
            </ng-template>
        </mat-tab>
        <mat-tab label="Chat mit Talent">
            <div class="chat-wrapper">
                <app-chat [chatData]="chatData" [talent]="talentProfileData" *ngIf="chatData" [chatInOverview]="false"></app-chat>
            </div>
        </mat-tab>
        <mat-tab label="AECdisc®-Potenzialanalyse">
            <div class="width-100 height-100 white-background" fxLayout="column" fxLayoutAlign="space-between start">
                <div *ngIf="!talentEvaluationData" class="layout-padding width-100">
                    <div class="aec-step">Es wurde noch kein Code für die AECdisc®-Potenzialanalyse angefordert.</div>
                    <div>Du kannst einen Zugang zu AECDisc für dieses Talent anfordern. Wenn du das tust, wird das Talent per Email benachrichtigt und kann den Test dann selbstständig ausfüllen. Das Ergebnis stellen wir nur dir zur Verfügung, so dass du es mit dem Talent durchsprechen kannst.
                    </div>
                </div>

                <div *ngIf="talentEvaluationData && !talentEvaluationData.testResultId" class="layout-padding width-100">
                    <div class="aec-step">Du hast bereits einen Code für das Talent angefordert.</div>
                    <div>Das Talent hat eine Mail erhalten, dass du einen Code bereitgestellt hast und kann den Test jetzt machen. Sobald der Test fertig ist, benachrichtigen wir dich und das Ergebnis ist hier abrufbar. Das Talent erhält das Ergebnis vorerst noch nicht, bis du mit ihm gesprochen hast. Es kann bis zu 24h dauern, bis der fertige Test in TALENTS verfügbar ist. Wenn du weißt, dass das Talent den Test schon fertiggestellt hat, kannst du unten auf "Teststatus aktualisieren" klicken, um das Ergebnis sofort abzuholen.
                    </div>
                    <div fxLayout="column" fxLayoutAlign="start center" class="aecdisc-code-wrapper">
                        <div>Zugangscode für das Talent </div>
                        <div style="color: #777">(Das Talent hat diesen Code bereits automatisch erhalten)</div>
                        <div class="aecdisc-code" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px">
                            <div>{{talentEvaluationData?.password}}</div>
                            <button mat-icon-button matTooltip="Kopieren" (click)="utilityService.copyToClipboard(talentEvaluationData?.password)"><i class="mdi mdi-content-copy"></i></button>
                        </div>
                    </div>
                </div>

                <div *ngIf="talentEvaluationData && talentEvaluationData.testResultId && !talentEvaluationData.approved" class="layout-padding width-100">
                    <div class="aec-step">Das Talent hat die AECdisc®-Potenzialanalyse gemacht.</div>
                    <div>
                        Du kannst das Ergebnis jetzt herunterladen. Das Talent sieht die Auswertung erst, wenn du sie über die Funktion "Ergebnis für Talent freigeben" freischaltest. Danach ist sie über den Reiter "Karrierecoach" für den Absolventen abrufbar.
                    </div>
                </div>

                <div *ngIf="talentEvaluationData && talentEvaluationData.testResultId && talentEvaluationData.approved" class="layout-padding width-100">
                    <div class="aec-step">Du hast die Analyse für das Talent freigeschaltet.</div>
                    <div>
                        Das Talent kann nun auch selbst über den Reiter "Karrierecoach" auf das Testergebnis zugreifen. Der Testvorgang ist damit beendet.
                    </div>
                </div>

                <div class="actions width-100 layout-padding" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="8px">
                    <button mat-button color="primary"
                            (click)="openAecdiscInformationDialog()">
                        <i class="mdi mdi-24px mdi-information-outline"></i>
                        Wie funktioniert AECdisc® in Talentagent?
                    </button>
                    <loading-button
                            *ngIf="!talentEvaluationData"
                            [buttonClass]="'mat-flat-button'" [color]="'primary'"
                            [loadingBool]="loadingTalentEvaluation"
                            (clickEvent)="createTalentEvaluation()">
                        Code anfordern
                    </loading-button>

                    <loading-button [color]="'primary'"
                                    *ngIf="talentEvaluationData && talentEvaluationData.status != 'CLOSED' && talentEvaluationData.testResultId == null"
                                    [loadingBool]="refreshingConsultantEvaluationGroup"
                                    (click)="triggerRefreshTalentAecdisc()">
                        Teststatus aktualisieren
                    </loading-button>

                    <loading-button
                            *ngIf="talentEvaluationData && talentEvaluationData.testResultId"
                            [buttonClass]="'mat-flat-button'" [color]="'primary'"
                            [disabledCondition]="downloadingAecdiscResult"
                            (clickEvent)="downloadAecdiscResult()">
                        Ergebnis herunterladen
                    </loading-button>

                    <button mat-flat-button color="primary" *ngIf="talentEvaluationData && talentEvaluationData.testResultId" (click)="openTalentEvaluationResult()">
                        Ergebnis öffnen
                    </button>

                    <loading-button
                            *ngIf="talentEvaluationData && talentEvaluationData.testResultId"
                            [buttonClass]="'mat-flat-button'" [color]="'primary'"
                            [loadingBool]="downloadingAecdiscResult"
                            [disabledCondition]="talentEvaluationData && talentEvaluationData.testResultId && talentEvaluationData.approved"
                            (clickEvent)="releaseAecdiscResult()">
                        Ergebnis für Talent freigeben
                    </loading-button>
                </div>
            </div>

        </mat-tab>
        <mat-tab label="Ereignishistorie">
            <div class="last-login" *ngIf="talentProfileData?.lastLogin">Letzte Aktivität: {{talentProfileData.lastLogin  | date: 'dd.MM.yy'}}</div>
            <table mat-table [dataSource]="talentEventHistoryData" class="width-100">
                <!-- Date Column -->
                <ng-container matColumnDef="date">
                    <th mat-header-cell *matHeaderCellDef> Datum</th>
                    <td mat-cell *matCellDef="let element"> {{element.occurred | date: 'dd.MM.yy HH:mm'}} Uhr</td>
                </ng-container>

                <!-- Event Column -->
                <ng-container matColumnDef="event">
                    <th mat-header-cell *matHeaderCellDef> Event</th>
                    <td mat-cell *matCellDef="let element"> {{element.logDtype | eventLog: element.positionTitle}} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumnsEventHistory"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsEventHistory;"></tr>
            </table>
        </mat-tab>
        <mat-tab label="Notizen">
            <div class="width-100 height-100 white-background">
                <mat-form-field class="width-100 layout-padding white-background height-100-68">
                    <mat-label>Notizen</mat-label>
                    <textarea #note matInput maxlength="4000" rows="20" cols="40" [formControl]="comment"
                              class="height-100"></textarea>
                    <mat-hint align="end">{{comment?.value?.length}} / 4000</mat-hint>
                </mat-form-field>
                <div class="actions width-100 layout-padding" fxLayout="row" fxLayoutAlign="end center">
                    <button mat-flat-button color="primary"
                            (click)="updateContactComment()">
                        Änderungen Speichern
                    </button>
                </div>
            </div>

        </mat-tab>

    </mat-tab-group>

<dialog-header dialogHeadline="Kontaktinformationen"></dialog-header>
<div class="content">
    <h2>Kontaktprofil Update</h2>
    <div style="padding:56.19% 0 0 0;position:relative;">
        <iframe src="https://player.vimeo.com/video/1063102266?title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write; encrypted-media"
                style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Talents Update - Interessen &amp; Coach Ressourcen"></iframe>
    </div>
    <script src="https://player.vimeo.com/api/player.js"></script>
    </div>
<div class="dialog-action-bar">
    <button mat-button (click)="close(true)">Verstanden, nicht mehr anzeigen</button>
    <button mat-button color="primary" (click)="close(false)">Schließen</button>
</div>

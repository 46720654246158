<dialog-header dialogHeadline="Talent bewerten"></dialog-header>
<div id="dialog-content-wrapper" fxLayout="column" fxLayoutAlign="space-between start">

    <div class="dialog-main-content width-100 layout-padding" [formGroup]="ratingForm">
        <loading-spinner [loadingBool]="loadingTalent">
            <h3><b>Bewertung von {{talentData?.firstName}} {{talentData?.lastName}}</b></h3>

            <div fxLayout="column" fxLayoutGap="25px" class="checkbox-questions">
                <div class="question-wrapper">
                    <label id="contact-radio-groub-label">Hattest du Kontakt mit dem Talent?</label>
                    <mat-radio-group fxLayout="column" fxLayoutGap="8px"
                                     class="question"
                                     formControlName="consultantReachedTalent"
                                     aria-labelledby="contact-radio-groub-label">
                        <mat-radio-button [value]="'TalentReached'">Ich hatte Kontakt mit dem Talent</mat-radio-button>
                        <mat-radio-button [value]="'TalentUnreachable'">Ich konnte das Talent nicht erreichen</mat-radio-button>
                        <mat-radio-button [value]="'NoIntentToReachTalent'">Ich wollte das Talent nicht erreichen</mat-radio-button>
                    </mat-radio-group>
                </div>

                <div class="question-wrapper" *ngIf="ratingForm.controls.consultantReachedTalent.value == 'TalentReached'">
                    <label id="consultant-job-radio-groub-label">Hast du das Talent auf die Beratertätigkeit angesprochen?</label>
                    <mat-radio-group fxLayout="column" fxLayoutGap="8px"
                                     class="question"
                                     formControlName="consultantJobQuestion"
                                     aria-labelledby="contact-radio-groub-label">
                        <mat-radio-button [value]="'yes'">Ja</mat-radio-button>
                        <mat-radio-button [value]="'no'">Nein</mat-radio-button>
                    </mat-radio-group>
                </div>

                <div class="question-wrapper" *ngIf="ratingForm.controls.consultantJobQuestion.value">
                    <label *ngIf="ratingForm.controls.consultantJobQuestion.value == 'yes'">Was ist rausgekommen?</label>
                    <label *ngIf="ratingForm.controls.consultantJobQuestion.value == 'no'">Warum nicht?</label>
                    <mat-radio-group fxLayout="column" fxLayoutGap="8px"
                                     class="question"
                                     formControlName="consultantJobAnswer"
                                     aria-labelledby="contact-radio-groub-label">
                        <mat-radio-button *ngIf="ratingForm.controls.consultantJobQuestion.value == 'yes'"
                                          [value]="'YesButNoInterest'">Hat kein Interesse</mat-radio-button>
                        <mat-radio-button *ngIf="ratingForm.controls.consultantJobQuestion.value == 'yes'"
                                          [value]="'YesButNotSuitable'">Ich halte es für ungeeignet</mat-radio-button>
                        <mat-radio-button *ngIf="ratingForm.controls.consultantJobQuestion.value == 'yes'"
                                          [value]="'YesTransferToMlp'">Werde an meinen LHT übergeben</mat-radio-button>
                        <mat-radio-button *ngIf="ratingForm.controls.consultantJobQuestion.value == 'no'"
                                          [value]="'FalseToDo'">Werde ich noch tun</mat-radio-button>
                        <mat-radio-button *ngIf="ratingForm.controls.consultantJobQuestion.value == 'no'"
                                          [value]="'FalseDontDoIt'">Ich kümmere mich darum nicht</mat-radio-button>
                        <mat-radio-button *ngIf="ratingForm.controls.consultantJobQuestion.value == 'no'"
                                          [value]="'FalseNotSuitable'">Ich halte es für ungeeignet</mat-radio-button>
                    </mat-radio-group>
                </div>


                <div class="question-wrapper" *ngIf="ratingForm.controls.consultantReachedTalent.value == 'TalentReached'">
                    <label id="knewAboutContact-label">War dem Kontakt bewusst, dass er einen Karrierecoach bekommen hat?</label>
                    <mat-radio-group fxLayout="row" fxLayoutGap="8px"
                                     class="question"
                                     formControlName="knewAboutContact"
                                     aria-labelledby="knewAboutContact-label">
                        <mat-radio-button [value]="true">Ja</mat-radio-button>
                        <mat-radio-button [value]="false">Nein</mat-radio-button>
                    </mat-radio-group>
                </div>

                <div class="question-wrapper" *ngIf="ratingForm.controls.consultantReachedTalent.value == 'TalentReached'">
                    <label id="useableForFinancialAdvice-label">Siehst du eine Chance den Kontakt für die Finanzberatung zu gewinnen?</label>
                    <mat-radio-group fxLayout="row" fxLayoutGap="8px"
                                     class="question"
                                     formControlName="useableForFinancialAdvice"
                                     aria-labelledby="useableForFinancialAdvice-label">
                        <mat-radio-button [value]="true">Ja</mat-radio-button>
                        <mat-radio-button [value]="false">Nein</mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>



            <div *ngIf="ratingForm.controls.consultantReachedTalent.value == 'TalentReached'" class="m-top30"><b>Wie gut kannst du allgemein mit dem Talent arbeiten?</b></div>
            <div *ngIf="ratingForm.controls.consultantReachedTalent.value == 'TalentReached'">
                <div fxLayout="row" fxLayoutAlign="start center" class="lead-quality">
                    <div (click)="changeLeadQuality('Low')">
                        <i [ngClass]="{'selected-frown': ratingForm.controls.talentRating.value == 'Low'}"
                           class="mdi mdi-emoticon-sad-outline"></i>
                    </div>
                    <div (click)="changeLeadQuality('Medium')">
                        <i [ngClass]="{'selected-meh': ratingForm.controls.talentRating.value == 'Medium'}"
                           class="mdi mdi-emoticon-neutral-outline"></i>
                    </div>
                    <div (click)="changeLeadQuality('High')">
                        <i [ngClass]="{'selected-smile': ratingForm.controls.talentRating.value == 'High'}"
                           class="mdi mdi-emoticon-happy-outline"></i>
                    </div>
                </div>


                <div class="m-top15" style="min-height: 20px">
                    <div *ngIf="ratingForm.controls.talentRating.value == 'Low'" class="inf0">
                        <span class="selected-frown">SCHLECHT</span> - Ich kann mit dem Talent überhaupt nichts anfangen.
                    </div>
                    <div *ngIf="ratingForm.controls.talentRating.value == 'Medium'">
                        <span class="selected-meh">MITTEL</span> - Ich kann mit dem Talent arbeiten.
                    </div>
                    <div *ngIf="ratingForm.controls.talentRating.value == 'High'">
                        <span class="selected-smile">GUT</span> - Ich kann sehr gut mit dem Talent arbeiten, bitte mehr
                        solche Talente!
                    </div>
                </div>
            </div>

            <mat-form-field class="width-100 m-top30" appearance="fill">
                <mat-label>Willst du Uniwunder noch etwas anderes Mitteilen? (optional)</mat-label>
                <textarea matInput formControlName="other" cdkTextareaAutosize rows="5"></textarea>
            </mat-form-field>

        </loading-spinner>
    </div>

    <div class="dialog-action-bar" fxLayoutGap="10px">
        <button mat-flat-button (click)="close()">Schließen</button>
        <loading-button [disabledCondition]="ratingForm.invalid" color="primary" [loadingBool]="loadingCreateRating"
                        (clickEvent)="rateContact()">
            Bewertung speichern
        </loading-button>
    </div>
</div>


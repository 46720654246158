<div [ngClass]="{
  'light-teal-chip': (chip.type == 'Salary' || chip.type == 'WorkingHours' || chip.type == 'HomeOffice'),
  'grey-chip': chip.type == 'Origin',
  'primary-chip': chip.type == 'TopMatch',
  'highlight-primary-element': chip.type == 'TopMatch',
  'extra-light-chip': (chip.type == 'Salary' || chip.type == 'WorkingHours' || chip.type == 'HomeOffice') && topjob
}" class="chip"
     [title]="chip.type == 'Origin' ? 'Talentagent importiert auch Stellen von anderen Plattformen wie hier ' + getText(chip) + ', um das Angebot für die Talente zu erhöhen. Die Unternehmen, die diese Stellen ausschreiben, sind idR keine Uniwunder-Kunden' : ''">
    {{ getText(chip) }}
</div>
